/* barlow-100normal - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Barlow Thin '),
    local('Barlow-Thin'),
    url(/static/media/barlow-latin-100.27a1352d.woff2) format('woff2'), 
    url(/static/media/barlow-latin-100.f803774f.woff) format('woff'); /* Modern Browsers */
}

/* barlow-100italic - latin */
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Barlow Thin italic'),
    local('Barlow-Thinitalic'),
    url(/static/media/barlow-latin-100italic.79b0e1ed.woff2) format('woff2'), 
    url(/static/media/barlow-latin-100italic.77aae2f6.woff) format('woff'); /* Modern Browsers */
}

/* barlow-200normal - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Barlow Extra Light '),
    local('Barlow-Extra Light'),
    url(/static/media/barlow-latin-200.bac46e2a.woff2) format('woff2'), 
    url(/static/media/barlow-latin-200.a43ca421.woff) format('woff'); /* Modern Browsers */
}

/* barlow-200italic - latin */
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
    local('Barlow Extra Light italic'),
    local('Barlow-Extra Lightitalic'),
    url(/static/media/barlow-latin-200italic.9f03aa74.woff2) format('woff2'), 
    url(/static/media/barlow-latin-200italic.8253a3ff.woff) format('woff'); /* Modern Browsers */
}

/* barlow-300normal - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Barlow Light '),
    local('Barlow-Light'),
    url(/static/media/barlow-latin-300.f780d6f9.woff2) format('woff2'), 
    url(/static/media/barlow-latin-300.3b156c61.woff) format('woff'); /* Modern Browsers */
}

/* barlow-300italic - latin */
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Barlow Light italic'),
    local('Barlow-Lightitalic'),
    url(/static/media/barlow-latin-300italic.f2d94c73.woff2) format('woff2'), 
    url(/static/media/barlow-latin-300italic.5621e804.woff) format('woff'); /* Modern Browsers */
}

/* barlow-400normal - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Barlow Regular '),
    local('Barlow-Regular'),
    url(/static/media/barlow-latin-400.0f9d3b56.woff2) format('woff2'), 
    url(/static/media/barlow-latin-400.90eaa404.woff) format('woff'); /* Modern Browsers */
}

/* barlow-400italic - latin */
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Barlow Regular italic'),
    local('Barlow-Regularitalic'),
    url(/static/media/barlow-latin-400italic.06ecc05a.woff2) format('woff2'), 
    url(/static/media/barlow-latin-400italic.83ee73d6.woff) format('woff'); /* Modern Browsers */
}

/* barlow-500normal - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Barlow Medium '),
    local('Barlow-Medium'),
    url(/static/media/barlow-latin-500.9c43f43c.woff2) format('woff2'), 
    url(/static/media/barlow-latin-500.d72cf851.woff) format('woff'); /* Modern Browsers */
}

/* barlow-500italic - latin */
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Barlow Medium italic'),
    local('Barlow-Mediumitalic'),
    url(/static/media/barlow-latin-500italic.864fe9bb.woff2) format('woff2'), 
    url(/static/media/barlow-latin-500italic.50adbfda.woff) format('woff'); /* Modern Browsers */
}

/* barlow-600normal - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Barlow SemiBold '),
    local('Barlow-SemiBold'),
    url(/static/media/barlow-latin-600.9126e377.woff2) format('woff2'), 
    url(/static/media/barlow-latin-600.18a5c325.woff) format('woff'); /* Modern Browsers */
}

/* barlow-600italic - latin */
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Barlow SemiBold italic'),
    local('Barlow-SemiBolditalic'),
    url(/static/media/barlow-latin-600italic.a7e86005.woff2) format('woff2'), 
    url(/static/media/barlow-latin-600italic.c8ef9c25.woff) format('woff'); /* Modern Browsers */
}

/* barlow-700normal - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Barlow Bold '),
    local('Barlow-Bold'),
    url(/static/media/barlow-latin-700.fa61eccc.woff2) format('woff2'), 
    url(/static/media/barlow-latin-700.09fd7142.woff) format('woff'); /* Modern Browsers */
}

/* barlow-700italic - latin */
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Barlow Bold italic'),
    local('Barlow-Bolditalic'),
    url(/static/media/barlow-latin-700italic.687db462.woff2) format('woff2'), 
    url(/static/media/barlow-latin-700italic.c5f56eea.woff) format('woff'); /* Modern Browsers */
}

/* barlow-800normal - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Barlow ExtraBold '),
    local('Barlow-ExtraBold'),
    url(/static/media/barlow-latin-800.cf173a0a.woff2) format('woff2'), 
    url(/static/media/barlow-latin-800.85dcdeac.woff) format('woff'); /* Modern Browsers */
}

/* barlow-800italic - latin */
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Barlow ExtraBold italic'),
    local('Barlow-ExtraBolditalic'),
    url(/static/media/barlow-latin-800italic.f4d1d64d.woff2) format('woff2'), 
    url(/static/media/barlow-latin-800italic.cf5c9daf.woff) format('woff'); /* Modern Browsers */
}

/* barlow-900normal - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Barlow Black '),
    local('Barlow-Black'),
    url(/static/media/barlow-latin-900.03ccd4a4.woff2) format('woff2'), 
    url(/static/media/barlow-latin-900.36229023.woff) format('woff'); /* Modern Browsers */
}

/* barlow-900italic - latin */
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Barlow Black italic'),
    local('Barlow-Blackitalic'),
    url(/static/media/barlow-latin-900italic.6520e9e9.woff2) format('woff2'), 
    url(/static/media/barlow-latin-900italic.250f89c8.woff) format('woff'); /* Modern Browsers */
}


